const GOOGLE_API_KEY="AIzaSyDAYu6WikLQs_AreoA5i3_gWeid86WhbSw";

export const YOUTUBE_VEDIO_API="https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&chart=mostPopular&maxResults=50&regionCode=US&key="+GOOGLE_API_KEY


export const YOUTUBE_LIKED_API="https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=Ks-_Mh1QhMc%2Cc0KYU2j0TM4%2CeIho2S0ZahI&key="+GOOGLE_API_KEY


export const YOUTUBE_SEARCH_API="https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=14&key="+GOOGLE_API_KEY+"&q="

export const GOOGLE_SEARCH_API="https://suggestqueries.google.com/complete/search?output=toolbar&ds=yt&hl=en&q="

// "https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=14&key=AIzaSyDAYu6WikLQs_AreoA5i3_gWeid86WhbSw&q="





